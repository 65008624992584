<template>
  <form-field :name="field.name">
    <v-autocomplete
      v-model="field.value"
      :items="items"
      :menu-props="{maxHeight:'auto'}"
      v-bind="field.props"
      combobox
      dense
      box
      hint="Select or type a new race"
      label="Enter race"
      persistent-hint
    />
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  mixins: [formField],
  data () {
    return {
      items: ['Malay', 'Chinese', 'Indian', 'Others'],
    }
  },
  computed: {
    avatar: function () {
      for (const item of this.items) { if (item.avatar) return true }

      return false
    },
  },
  mounted: function () {
    this.field.props.rules || this.$set(this.field.props, 'rules', [val => !!val || 'Race required!'])
  },
}
</script>
